const jp = {
  demo: {
    title: '日本',
    introduction: '',
  },
  resetpass: {
    mesage: 'パスワードを忘れましたか？',
    info: 'アカウントに関連付けられたメールアドレスを入力してください。パスワードをリセットするためのリンクを送信します。',
    reset: 'リセット',
    back: '戻る',
    sent: 'リクエストが正常に送信されました',
    confirmation: '確認メールを送信しました',
    check: 'メールを確認してください',
    check1: '確認コードを入力してください',
    verify: '確認',
    resend: 'コードを再送信',
    donthave: 'コードがありませんか？',
    return: 'ホームに戻る',
    success: 'リクエストが正常に送信されました！',
    success2: '確認用の6桁のコードがメールアドレスに送信されました。',
    success3: 'メールアドレスの確認のため、以下のボックスにコードを入力してください。',
    newpass: '新しいパスワードを更新する',
    confirmpass: '新しいパスワードを確認する',
    pass: 'パスワード',
    checkpegatina: '物理的なステッカーを見る',
    checkpegatina2: 'お客様の機密データのセキュリティを確保するため、物理的なステッカーに6桁の確認用PINが印刷されています。このPINを下のボックスに入力すると、機密情報へのアクセスが安全になります。PINをお持ちでない場合は、ログインすれば入力の必要はありません。',
    errorpin: '間違ったピン'
  },
  newparameter: {
    back: '戻る',
    next: '次へ',
    scope: 'パラメータのスコープ',
    type: 'パラメータの種類',
    config: '設定',
    posology: '処方に従って販売',
    swap: '処方の交換',
    notdispense: '非表示',
    priority: '優先度付け',
    add: '完了',
    priorityconfirm: '今後、お客様の優先医薬品が優先的に扱われます。',
    posologyconfirm: '今後、処方に従ってのみ販売されます。',
    selectsust: '置換する処方を選択',
    selectsust2: '表示する処方を選択',
    notdispence2: '非表示にする処方を選択',
    number: '番号',
    scopetable: '対象',
    name: '名前',
    typeparameter: 'パラメータのタイプ',
    code: '国内コード',
    patient: '患者',
    center: 'センター',
    pharmacy: '薬局',
    swaptype: '処方の交換',
    prioritytype: '優先度付け',
    posologytype: '処方に従ってのみ販売',
    notdispencetype: '非表示',
    no_financied: '補助対象のみ',
    no_narcotic: '麻薬を販売しない',
    maxstock: '在庫の多いもの',
    max_box_dps: '最大単位数',
    no_regex: '「ワード」で非表示',
    order_lab: 'ラボ優先',
    check_stock: '在庫ありで販売',
    force_posology: '処方の国内コードで販売',
    laboratory: 'ラボ一覧',
    replace_max_stock: '最大在庫の置換',
    cst_change: 'ホッパーの最後の補充',
  },
  table: {
    column: '列',
    find: '列を見つける。',
    columntitle: '列タイトル',
    hyde: 'すべて非表示',
    mark: 'すべて選択',
    filters: 'フィルター',
    density: '表示密度',
    density1: 'コンパクト',
    density2: '通常',
    density3: '大',
    export: 'エクスポート',
    operators: 'オペレーター',
    contains: '含む',
    equals: '等しい',
    start: 'で始まる',
    end: 'で終わる',
    empty: '空白',
    noempty: '空白以外',
    value: '値',
    filtervalue: '値でフィルター',
    download: 'CSVとしてダウンロード',
    print: '印刷',
    download2: 'Excelとしてダウンロード',
    columnMenuLabel: 'メニュー',
    columnMenuShowColumns: '列を表示',
    columnMenuManageColumns: '列を管理',
    columnMenuFilter: 'フィルター',
    columnMenuHideColumn: '列を非表示',
    columnMenuUnsort: '並べ替え解除',
    columnMenuSortAsc: '昇順で並べ替え',
    columnMenuSortDesc: '降順で並べ替え',
  },
  menu: {
    general: 'メニュー',
    order: '注文管理',
    'historic Orders': '注文履歴',
    catalog: '医薬品',
    patient: '患者',
    posology: '服用量',
    newpro: '生産',
    Laboratorys: 'ラボ',
    Config: '設定',
    management: '管理',
    user: 'ユーザー',
    profile: 'マイプロフィール',
    list: 'ユーザー管理',
    myinstallation: 'マイインストール',
    subscription: 'マイファーマシー',
    invoice: '請求書',
    centers: 'センター',
    centersmanage: 'センター管理',
    virtual: 'AI Mery',
    available: '購買注文',
    parameters: 'パラメータ',
    listrx: '患者別処方箋',
    reports: '電子処方箋',
    traceability: 'トレーサビリティ',
    alerts: 'アラート',
    history: '履歴',
    medpatient: '患者別医薬品',
    merge: '服用量のクロスチェック',
    take: '薬剤の配信',
    import: 'インポート',
    inicio: 'ホーム',
    upload: 'ファイルのインポート',
    parametersimport: 'インポートパラメータ',
    task: 'スケジュールされたタスク',
    electronic: '電子カード',
    availableia: '利用可能',
    match: '服用量/処方箋のクロスチェック',
    sellparameters: '販売パラメータ',
    form0: 'レポート',
    form1: '生産レポート',
    form2: '患者別医薬品',
    form3: 'トレーサビリティ',
    chat: 'チャット',
    calendar: 'カレンダー',
    support: 'テクニカルサポート',
    assist: 'サポート',
  },
  dropdown: {
    Home: 'ホーム',
    Profile: 'プロフィール',
    Settings: '設定',
    logout: 'ログアウト',
  },
  alert: {
    success: 'アクセスが許可されました',
    newuser: '新しいユーザー',
    verified: '検証済み',
    yes: 'はい',
    no: 'いいえ',
    edit: '編集',
    deleteuser: 'ユーザーを削除',
    confirmation: 'このユーザーを削除してもよろしいですか？',
  },
  myuser: {
    general: '一般',
    pass: 'パスワードの変更',
    allow: '許可されたファイル形式',
    sice: '最大ファイルサイズ',
    save: '変更を保存',
    about: '紹介',
    old: '現在のパスワード',
    new: '新しいパスワード',
    mustbe: 'パスワードは6文字以上である必要があります。',
    confirm: '新しいパスワードの確認',
    banned: '無効',
    applyban: 'アカウントの無効化を適用する。',
    savechanges: '変更を保存',
    upload: 'ファイルのアップロード',
    photo: '写真のアップロード',
    update: '写真の更新',
  },
  register: {
    mesage: '簡単で直感的な方法。',
    intro: 'アカウントを作成して、当社のソリューションをご利用ください。',
    intro2: '非常に簡単です！',
    name: '名前と姓',
    lastname: '姓',
    namepharma: '施設名',
    create: '登録',
    already: 'すでにアカウントをお持ちですか？',
    firstnamerec: '名前は必須です',
    lastnamerec: '姓は必須です',
    emailrec: 'メールアドレスは必須です',
    passrec: 'パスワードは必須です',
  },
  centers: {
    list: 'センターのリスト',
    title: 'センター情報',
    nif: 'NIF',
    date: '登録日',
    production: '生産モード',
    machinepro: '選択した装置',
    print1: '包装不可のものを印刷する。',
    print2: 'タイミングごとに印刷する。',
    print3: '必要に応じて印刷します。',
    code: '医薬品コードで',
    code2: '包装ファミリー別で',
    code3: '生物学的ファミリー別で',
    change: 'センターを変更しますか？',
    changeinstallation: '施設を変更しますか？',
    sure: '本当にセンターを変更しますか？',
    acept: '承諾する',
    addnew: '新しい既定のパターンを追加',
    namenewpred: 'パターン名',
    syncdate: '電子レシピの終了日を同期する',
  },
  listmedicine: {
    medication: '薬剤',
    search: '検索',
    filter: '薬剤フィルター',
    all: 'すべて',
    tipemedication: '薬剤',
    familya: '包装ファミリー',
    familyb: '生物学的ファミリー',
    inactive: '非アクティブ',
    active: 'アクティブ',
    ficha: '薬剤情報',
    brand: 'ブランド',
    generic: 'ジェネリック',
    needrecipe: '処方箋が必要',
    code: 'コード',
    name: '名前',
    codefamily: '生物学的ファミリーコード',
    namefamily: '生物学的ファミリー名',
    units: 'ユニット数',
    atc: 'ATCコード',
    packable: '包装可能',
    psicotropic: '向精神薬',
    narcotic: '麻薬',
    warning: '警告',
    replaceable: '交換可能',
    clinic: '臨床用途',
    use: '入院用途',
    comer: '市販',
    tld: 'TLD',
    para: 'パラファーマシー',
    control: '特別な医学的管理',
    drive: '運転効果',
    orphan: '孤児',
    recipe: '処方箋',
    bio: 'バイオ類似',
    save: '保存',
    cancel: 'キャンセル',
  },
  listpatient: {
    title: '患者リスト',
    search: '検索',
    active: 'アクティブ',
    ficha: '患者情報',
    name: '名前',
    dni: '身分証明書番号',
    born: '生年月日',
    ss: '社会保障番号',
    phone: '電話番号',
    sex: '性別',
    female: '女性',
    male: '男性',
    bed: 'ベッド',
    region: '地域',
    room: '部屋',
    address: '住所',
    country: '国',
    city: '市',
    cp: '郵便番号',
    mail: 'メール',
    save: '保存',
    cancel: 'キャンセル',
    sync: '同期',
    journal: '服薬履歴',
    electronic: '電子処方箋',
    group: 'グループ',
    doctorname: '「医師の名前'
  },
  production: {
    status: 'ステータス',
    title: '新しい生産',
    code: 'コード',
    datecreate: '作成日',
    dateproduction: '生産日',
    lastprox: '次回/前回の分配',
    initialdose: '開始/前回の分配',
    dateend: '終了日',
    cancel: 'キャンセル',
    pendent: '保留中',
    working: '作業中',
    produced: '生産済み',
    deleted: '削除された',
    repackaging: '再包装生産',
    pouch: '袋数',
    dose: '1回あたりの単位',
    printlabel: 'ラベルの印刷',
    labelsearch: '名前で検索',
  },
  dashboard: {
    title: 'ようこそ👋',
    titlecomment: '最先端のAIを使用して、市場で最も安全で信頼性の高いサービスを提供しています。',
    discover: 'もっと詳しく',
    patients: '患者',
    medication: 'アクティブな薬剤',
    production: '生産',
    caducity: '有効期限が近い薬剤',
    data: '最新の生産データ：',
    total: '合計販売数：',
    packable: '包装可能',
    unpackable: '包装不可',
    dispensed: '分配された',
    activpatient: 'アクティブな患者',
    activposology: 'アクティブな治療',
    totalcount: '月間販売数',
    type: '特徴',
    if_needed: '必要なら',
    iaquality: 'AI品質',
    merge: '正常',
    rxx: '処方箋なし',
    posology: '処方',
    reviwer: 'レビュー',
    dep: 'デポジット',
    ranking: 'トップ変更',
  },
  posology: {
    force_print: '印刷のみ',
    no_sync: '保護された',
    title: '処方',
    search: '検索',
    active: 'アクティブ',
    inactive: '非アクティブな薬剤',
    save: '保存',
    cancel: 'キャンセル',
    packable: '包装可能',
    unpackable: '包装不可',
    start: '開始日',
    end: '終了日',
    dosis: '投与量',
    period: '日数',
    cronic: '慢性',
    days: '日',
    hour: '時間',
    starthour: '開始時間',
    months: '月',
    january: '1月',
    february: '2月',
    march: '3月',
    april: '4月',
    may: '5月',
    june: '6月',
    july: '7月',
    august: '8月',
    september: '9月',
    october: '10月',
    november: '11月',
    december: '12月',
    week: '週間',
    ciclica: 'サイクリック日',
    ciclicb: 'サイクリック時間',
    especilavar: '変数',
    month: '毎月',
    aparte: '個別包装',
    optional: '必要なら',
    irreplaceable: '交換不可',
    moment: '瞬間',
    monday: '月曜日',
    tuesday: '火曜日',
    wednesday: '水曜日',
    thursday: '木曜日',
    friday: '金曜日',
    saturday: '土曜日',
    sunday: '日曜日',
    add: '服用を追加',
    name: '服用名',
    selecttitle: '薬剤の選択',
    select: '選択',
    addtoma: '追加',
    cannot: '空の処方は保存できません。',
    confirmation: '確認',
    confirmationtext: 'この治療を削除してもよろしいですか？',
    confirmationdelete: '削除',
    update: '変更が正常に保存されました。',
    error: '処方の削除中にエラーが発生しました。',
    erroradd: '処方の追加中にエラーが発生しました。',
    addsuccess: '正常に追加されました。',
    deletesuccess: '正常に削除されました。',
    errordate: '開始日は今日より前にすることはできません。',
    exist: 'すでに存在する服用。',
    periodless: '期間は日より短くすることはできません。',
    daysless: '日数は1より少なくすることはできません。',
    hourless: '時間は1より少なくすることはできません。',
    hourerror: '時間は24を超えることはできません。',
    dayerror: '日は31より大きく、0より小さくすることはできません。',
    delete: '処方を削除',
    note: 'ノート',
    interaction: '薬物相互作用',
    deletedose: '選択した治療を削除しますか？',
  },
  login: {
    mesage: 'こんにちは、ようこそ',
    introduction:
      'Lorem Ipsumは、印刷および組版業界のダミーテキストです。16世紀から、この業界の標準的なダミーテキストとして使用されています。未知の印刷業者がギャレー型のものを取り、それを活字のサンプル本にしました。それは5世紀以上も生き残り、基本的には変わらずに電子組版にも飛び込んでいます。LetrasetシートにLorem Ipsumのパッセージが含まれていた1960年代には、より最近ではAldus PageMakerなどのデスクトップパブリッシングソフトウェアが、Lorem Ipsumバージョンを含む様々なバージョンがリリースされました。',
    signin: 'IDOOSEにサインイン',
    details: '以下にデータを入力してください。',
    inicio: 'ログイン',
    agree: '登録することで、',
    terms: '利用規約',
    privacy: 'プライバシーポリシー',
    and: ' および ',
    account: '新しいユーザーですか？ ',
    started: 'アカウントを作成する',
    email: 'メールアドレス',
    password: 'パスワード',
    rememberme: '私を覚えてますか',
    forgotpassword: 'パスワードをお忘れですか？',
    requiremail: 'メールアドレスが必要です',
    requirepass: 'パスワードが必要です',
    requirement: '登録することで、私は ',
    requirement2: ' 利用規約',
    requirement3: ' と ',
    requirement4: 'プライバシーポリシー',
  },
  virtual: {
    list: '処方箋リスト',
    new: '新しい処方箋',
    active: 'アクティブ',
    expired: '期限切れ',
    expiredate: '有効期限日',
    renewdate: '更新日',
    raw: 'バンド/チップ/TSI/処方箋番号',
    order: 'バーコード（CATのみ）',
    review: 'レビュー',
    assignment: 'コードの割り当て',
    sell: '販売',
    create: 'オーダーを作成',
    title: 'スケジュールされたタスク',
    number: 'オーダー番号',
    status: 'ステータス',
    date: '開始日',
    patients: '患者数',
    titleparameters: '販売パラメータ',
    confirm: '選択した処方箋を削除しますか？',
    deletesinc: '選択した同期を削除しますか？',
    productionform: '生産レポート',
    prepareinform: 'レポートの準備',
    export: 'Excelにエクスポート',
  },
  trazability: {
    ema: 'ヨーロッパ医薬品庁（EMA）',
    text: 'ヨーロッパ医薬品庁（EMA）は、EUの分散型機関であり、EUの医薬品の科学的評価、監視、安全性の追跡に責任を負っています。EMAは、ヨーロッパ全域から数千人の専門家が関わるネットワーク組織です。これらの専門家は、EMAの科学委員会の作業を行っています。',
    discover: 'EMAの詳細',
    detections: '検出されたアラート',
    eligible: '修正可能なアラート',
    lastupdate: '最終更新',
    totals: '確認された合計医薬品',
  },
  reportpatientproductionlist: {
    lng_title: '生産された患者のレポート',
    lng_titlelistcenter: '患者リスト',
    lng_phone: '電話',
    lng_mail: 'メール',
    lng_num_production: '生産番号',
    lng_center: 'センター',
    lng_date_int: '開始日',
    lng_date_end: '終了日',
    lng_code: 'コード',
    lng_name: '名前',
    lng_send: '送信',
    lng_reception: '受信',
    lng_date: '日付',
    lng_no_medication: '調停なし',
    lng_reviwer: 'レビュー担当者:',
    lng_produced: '制作:',
  },
  reportmedicineproductionlist: {
    lng_title: '医薬品の集計レポート',
    lng_title2: '再充填レポート',
    lng_title3: '患者ごとの医薬品レポート',
    lng_phone: '電話',
    lng_mail: 'メール',
    lng_num_production: '生産番号',
    lng_center: 'センター',
    lng_date_int: '開始日',
    lng_date_end: '終了日',
    lng_code: 'コード',
    lng_name: '名前',
    lng_qty: '数量',
    lng_yes: 'はい',
    lng_no: 'いいえ',
    lng_packable: '包装可能',
    lng_date: '日付',
    lng_dev: 'ホッパー',
    lng_lot: 'ロット',
    lng_exp: '有効期限',
    lng_qty2: '残り',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: '包装不可の医薬品',
    lng_title_packing: '包装可能な医薬品',
    lng_mo2: '月曜日',
    lng_tu2: '火曜日',
    lng_we2: '水曜日',
    lng_th2: '木曜日',
    lng_fr2: '金曜日',
    lng_sa2: '土曜日',
    lng_su2: '日曜日',
    lng_title: '薬物治療計画',
    lng_phone: '電話',
    lng_mail: 'メール',
    lng_date: '日付',
    lng_num_patient: '患者番号',
    lng_name_patient: '名前',
    lng_dni: '身分証明書番号',
    lng_ss: '社会保障番号',
    lng_code_medicine: 'コード',
    lng_name_medicine: '医薬品名',
    lng_posology: '処方',
    lng_date_int: '開始日',
    lng_date_end: '終了日',
    lng_cronic: '慢性',
    lng_mo: '月',
    lng_tu: '火',
    lng_we: '水',
    lng_th: '木',
    lng_fr: '金',
    lng_sa: '土',
    lng_su: '日',
    lng_day: '日',
    lng_month: 'ヶ月',
    lng_ja: '1月',
    lng_fe: '2月',
    lng_ma: '3月',
    lng_ap: '4月',
    lng_my: '5月',
    lng_jn: '6月',
    lng_jl: '7月',
    lng_au: '8月',
    lng_se: '9月',
    lng_oc: '10月',
    lng_no: '11月',
    lng_de: '12月',
    lng_month_all: 'すべての月',
    lng_hour: '毎',
    lng_hour_x: '時間、次から',
  },

  reportchangeslist: {
    lng_title_not_packing: '包装不可の医薬品',
    lng_title_packing: '包装可能な医薬品',
    lng_mo2: '月曜日',
    lng_tu2: '火曜日',
    lng_we2: '水曜日',
    lng_th2: '木曜日',
    lng_fr2: '金曜日',
    lng_sa2: '土曜日',
    lng_su2: '日曜日',
    lng_title: '変更レポート',
    lng_phone: '電話',
    lng_mail: 'メール',
    lng_date: '日付',
    lng_num_patient: '患者番号',
    lng_name_patient: '名前',
    lng_dni: '身分証明書番号',
    lng_ss: '社会保障番号',
    lng_code_medicine: 'コード',
    lng_name_medicine: '医薬品名',
    lng_posology: '処方',
    lng_date_int: '開始日',
    lng_date_end: '終了日',
    lng_cronic: '慢性',
    lng_mo: '月',
    lng_tu: '火',
    lng_we: '水',
    lng_th: '木',
    lng_fr: '金',
    lng_sa: '土',
    lng_su: '日',
    lng_day: '日',
    lng_month: 'ヶ月',
    lng_ja: '1月',
    lng_fe: '2月',
    lng_ma: '3月',
    lng_ap: '4月',
    lng_my: '5月',
    lng_jn: '6月',
    lng_jl: '7月',
    lng_au: '8月',
    lng_se: '9月',
    lng_oc: '10月',
    lng_no: '11月',
    lng_de: '12月',
    lng_month_all: 'すべての月',
    lng_hour: '毎',
    lng_hour_x: '時間、次から',
  },
  sync: {
    new: '新しい同期を追加',
  },
  reportpatientjournallist: {
    lng_title: '患者の未払いレポート',
    lng_phone: '電話',
    lng_mail: 'メール',
    lng_date: '日付',
    lng_num_patient: '患者番号',
    lng_name_patient: '名前',
    lng_dni: '身分証明書番号',
    lng_ss: '社会保障番号',
    lng_code_medicine: 'コード',
    lng_name_medicine: '医薬品名',
    lng_prod: '生産された',
    lng_seller: '販売された',
    lng_aport: '提供された',
    lng_total: '合計',
  },
  reportpatientconsent: {
    lng_anx_1: '私は薬局の個別用量包装プログラムに参加したいと思います。',
    lng_anx_2:
      'そのプログラムは、医薬品を個別用量包装システムに調製することを含んでいることを理解しています。',
    lng_anx_3:
      '私は完全に自発的にこのプログラムに参加し、この同意を取り消すことでいつでもこのプログラムから離れることができます。同意の撤回欄に記入するだけです。',
    lng_anx_4:
      'このプロジェクトに参加することは、市販品を使用する場合、医薬品を薬局にそのまま保管することを意味します。薬剤師がその適切な管理と保存を行います。',
    lng_anx_5: '私は次を約束します：',
    lng_anx_51: '薬剤師に私の医薬品の変更を通知する。',
    lng_anx_52: '個別用量包装の条件を守る。',
    lng_anx_53: '前週の空のデバイスを提出して、遵守と使用の間違いを確認します。',
    lng_anx_6: '薬剤師は次を約束します：',
    lng_anx_61: '薬剤品に関する問題があれば、私に連絡します。',
    lng_anx_62: '個別用量包装デバイスと医薬品の正しい使用法に関する情報を提供します。',
    lng_anx_7: '私は、このプログラムへの参加に必要な個人データの処理を薬剤師に許可します。',
    lng_consent: '同意書',
    lng_date: '日付',
    lng_dni: '身分証明書番号',
    lng_don_d: '氏名',
    lng_lopd_1:
      '*個別用量包装プログラムへの参加に提供される個人データは、SPDという名前のファイルに含まれ、適切にスペインデータ保護庁に登録されています。このファイルの責任者は薬局です。',
    lng_lopd_2:
      '適切なデータ保護法に従い、薬局はこれらのデータを管理します。これらのデータは、患者の個別用量包装デバイスを管理するためだけに使用され、医療関係者、家族、または患者と関連する介護者に提供される場合があります。これは治療を確認し、遵守を向上させるためのものであり、広告または商業目的には使用されません。これらのデータは薬剤師の業務上の秘密の対象となります。これらのファイルに登録されている人々は、法的に定められた条件で、これらのデータにアクセスし、修正、またはキャンセルする権利があります。',
    lng_mail: 'メール',
    lng_name_d: '患者/法定代理人',
    lng_name_patient: '名前',
    lng_name_ph_d: '薬剤師',
    lng_name_ph_sin_d: '薬剤師の署名',
    lng_name_sin_d: '患者/法定代理人の署名',
    lng_num_patient: '患者番号',
    lng_phone: '電話',
    lng_ss: '社会保障番号',
    lng_title: '患者の同意書',
  },
  Bob: {
    ordernumber: '注文番号',
    status: 'ステータス',
    process: '進捗状況',
    Startorder: '注文開始',
    createdate: '作成日',
    selling: '販売中',
    reviewing: 'レビュー中',
    wait: '保留中',
    sevem: 'SEVEM',
    patient: '患者',
    drug: '医薬品',
    recipe: '電子処方箋',
    initialdate: '治療開始日',
    expiredate: '有効期限',
    posology: '投与量',
    scan: 'コードをスキャンする',
    dep: 'デポジット',
    without1: '処方箋なし',
    without2: '投与量なし',
    reviwer: 'レビュー',
    correct: '正しい',
    lot: 'ロット',
    neworder: '新しい注文',
    deleteorder: '選択した作業注文を削除しますか？',
    numberorder: '注文番号：',
    history: 'トレースビリティの履歴',
    scancodes: 'コードの割り当て',
    forceall: 'すべてをCNに強制',
    last: '最終更新',
    deleteparameter: '選択したパラメータを削除しますか？',
    sellformtitle: '販売レポート',
  },
  official: {
    error: 'ドキュメントが見つかりません。',
  },
  display: {
    prospectus: '概要',
    technical: '技術仕様書',
    unpackable: '包装不可',
  },
  notfound: {
    sorry: 'データが見つかりません。',
    sorry2: '申し訳ありませんが、お探しのページが見つかりませんでした。',
    home: 'ホーム',
  },
  editproduction: {
    edit: '生産の編集',
    confirm: '生産を変更してもよろしいですか？',
    home: 'ホーム',
    predose: '事前投与パターン',
    addnewpre: '新しい事前投与を追加',
    nametome: '投与名',
    hourtome: '投与時間',
    confirmationtext: 'この事前投与を削除してもよろしいですか？',
  },
  blisterpro: {
    process: '進行中',
    sorry2: '申し訳ありませんが、お探しのページが見つかりませんでした。',
    home: 'ホーム',
  },
  various: {
    exhausted: '疲れ果てた',
    datapatient: '患者データ',
    center: 'センターデータ',
    expiredon: '有効期限：',
    synclist: '同期されたアイテム',
    debt: '負債',
    drug: '薬品',
    produced: '生産済み',
    sell: '販売済み',
    provided: '提供済み',
    total: '合計',
    date: '日付',
    Details: '詳細',
    event: 'イベント',
    units: '単位',
    production: '生産',
    sell2: '販売',
    add: '追加',
    historic: '履歴',
    historic2: 'トレーサビリティ履歴',
    ns: 'シリアル番号',
    available: '利用可能',
    next: '次回',
    recomended: '推奨',
    notrecomended: '非推奨',
    register: '登録',
    registerdate: '登録日',
    from: '開始',
    to: '終了',
    datapicker: '日付の選択',
    download: 'ダウンロード',
    tpills: '合計単位数',
    tneed: '不足',
    rbox: '箱数',
    result: '結果',
    predetermlist: '事前投与リスト',
    deletepredet: '選択した事前投与を削除してもよろしいですか？',
    no_verifi: '未確認',
    verifi: '確認済み',
    installation: '私の施設',
    exportamts: 'ATMSをエクスポート',
    confirmdose: '投与を登録しますか？',
    whynot: 'キャンセル理由',
  },
  tooltip: {
    refill: '補充レポート',
    label: 'ラベルを印刷',
    edit: '生産を編集',
    grouped: 'グループ化された医薬品レポート',
    report1: '患者-医薬品レポート',
    delivery: '納品書',
    archived: 'アーカイブ済み',
    delete: '生産を削除',
    generateproduction: '生産を生成',
    repackaging: '再包装生産',
    start: '生産を開始',
    list: '患者リスト',
    add: '新規患者',
    listposology: '患者の投与法リスト',
    official: '公式投与法レポート',
    activate: 'WhatsAppの有効化/無効化',
    consent: '同意書',
    alllabel: 'すべて',
    changes: '変更レポート',
    sevem: '販売を終了',
    new: '新しいタスク',
    copy: 'コピー',
    insert: '挿入',
    newparameter: '新しいパラメーター',
    center: '新しいセンター',
  },
  import: {
    e_title: '注意！インポートされたファイルが間違っている可能性があります',
    e_dialog: '居住地からファイルをインポートしたようです：',
    e_dialog1: '居住地からではなく、代わりに：',
    e_dialog2: '状況を確認してください。',
    i_title: 'ファイルのインポート',
    i_type: 'ファイル形式',
    i_drop: 'ファイルの選択',
    i_dropb: 'ここにファイルをドラッグアンドドロップするか、コンピューターから選択してください。',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: '利用可能なライセンスでモジュールがありません',
    subtitle_: '商業部門にお問い合わせいただき、デモをリクエストしてください！',
    return_: 'アプリに戻る',
  },
  permit: {
    permit: '権限を管理する',
  },
  permissionsgroup: {
    1: '医薬品カタログ',
    2: '患者管理',
    3: '患者の投与法',
    4: '生産モジュール',
    5: 'インポート',
    6: 'IA Mery',
    7: 'トレーサビリティ',
    8: 'ユーザー管理',
    9: 'センター管理',
    10: '私の薬局のデータを管理',
    11: '医薬品の配達',
    12: 'レポート',
  },
  permissions: {
    1: '医薬品カタログの有効化',
    2: '医薬品の変更',
    3: '患者データの表示',
    4: '患者データの変更',
    5: '電子処方箋の表示',
    6: '電子処方箋の追加または削除',
    7: '同期の表示',
    8: '同期の追加または削除',
    9: '患者の消費量の表示',
    10: '患者の消費量の変更',
    11: '投与法の有効化',
    12: '投与法の変更',
    13: '生産モジュールの有効化',
    14: '生産の作成',
    15: '生産の送信/手動ブリスターセクション',
    16: '生産の編集',
    17: '生産の削除',
    18: 'インポートモジュールの有効化',
    19: 'システムへのファイルのインポート',
    20: 'IA Meryの有効化',
    21: 'スケジュールされたタスクの表示',
    22: 'タスクの追加',
    23: '健康保険カードの表示',
    24: 'カードの挿入',
    25: '利用可能なものの表示',
    26: '投与法/処方箋のクロスチェックの表示',
    27: '販売パラメータの有効化',
    28: '販売パラメータの作成',
    29: '患者セクションの有効化',
    30: '投与管理モジュールの有効化',
    31: '患者への医薬品の配達',
    32: 'トレーサビリティの有効化',
    33: 'ユーザー管理の有効化',
    34: 'ユーザーの変更',
    35: 'センター管理の有効化',
    36: 'センターの変更',
    37: 'マイインストールの有効化',
    38: 'マイインストールの変更',
    39: 'レポートの有効化',
    40: '生産レポート',
  },
  control_takes: {
    1: '不要',
    2: '薬剤不足',
    3: '薬剤が損傷している',
    4: '患者が拒否した',
    5: 'その他',
    cause_not_take: '理由',
    6: '誤った時間',
    7: '薬剤の変更',
    8: '追加',
    9: '削除',
    10: '服用の記録',
  },
  home: {
    home: 'ホーム',
    title: 'あなたの薬の管理をシンプルに',
    titletex:
      '薬の管理における最先端へようこそ。当社の個別服薬システム（SPD）ソフトウェアは、健康管理におけるあなたの新しいパートナーです。薬の準備に関する複雑さを忘れてください。直感的でカスタマイズされた当社のソリューションにより、治療への適応が簡単で効率的な体験になります。',
    available: '利用可能',
    benefits: 'SPDのメリット',
    titlebenefit1: '服薬通知',
    benefit1:
      '当社のアプリにより、各服薬のための正確なリマインダーを提供することで、日々のルーティンを最適化します。忘れる心配はなくなり、あなたの健康を確実にします。また、薬の相互作用や非互換性を確認し、健康管理を完全かつ安全に行います。',
    titlebenefit2: 'パーソナライズされたサービス',
    benefit2:
      '健康管理における新しい快適さのスタンダードを体験してください。IDOOSEを使用すると、高度に訓練された薬剤師によって注意深く調製された薬を受け取ることができます。私たちは、ストレスのない体験を提供し、質をもたらし、各服薬を簡素化します。',
    titlebenefit3: '薬の準備',
    benefit3:
      '箱詰めの手間はありません。当社の連続ユニドーズシステムを使用すると、薬の服用が開封するだけで簡単になります。各服薬には、説明書を含む詳細な情報が含まれており、治療の各段階で快適さと明確さを提供します。',
    appidoose: 'IDOOSEであなたの薬を忘れることはありません。',
    appidoosetext: 'アプリをダウンロードして登録し、最寄りの薬局と連絡を取りましょう。',
    listposology: '患者別投与法',
    design: 'あなたに合わせて設計',
    partner: '当社と協力したいですか？',
    partner2: 'パートナーになる',
    partnertext:
      '当社は、個別服薬システム（SPD）への人工知能の適用で不動のリーダーです。当社を選ぶことで、生産の効率を確保し、生産プロセスのエラーを完全に排除します。当社の経験を信頼して、オペレーションを最高水準の精度と安全性に引き上げてください。',
    prices: '価格とプラン',
    licence: 'ライセンス',
    patient: '患者管理',
    production: '生産モジュール',
    import: 'ファイルのインポート',
    blister: '手動ブリスター生産',
    update: 'アップデート',
    support: 'テクニカルサポート',
    patient30: '最大50人の患者まで',
    patient31: '無制限の患者',
    rx: '電子処方箋への接続',
    inform: 'CCAAの投与報告',
    warning: '電子処方箋の変更',
    next: '次の服薬',
    mach: '投与法と電子処方箋のクロスチェック',
    sell: '販売モジュール',
    question: 'まだ疑問がありますか？',
    question2:
      '最適なアドバイスを提供するために、あなたが薬局、病院、または研究所を代表しているかどうかをお知らせください。こうすることで、あなたの業界の特性に応じたカスタマイズされた情報を提供できます。',
    contact: 'お問い合わせ',
    reserved: 'すべての権利を保有しています、制作',
    forgotpasswordintro:
      'アカウントに関連付けられている電子メールアドレスを入力してください。パスワードをリセットするためのリンクが送信されます。',
    request: 'リクエストを送信',
    returnto: 'ログインページに戻る',
    choose: '個人用の薬を調合するロボットは必要か？',
    choose1: 'パーソナライズされた投薬準備ロボットをご覧ください。',
    choose2:
      '私たちは、お客様の特定のニーズに適応する幅広いロボットを取り揃えています。生産量、自動化レベル、ブリスターやバッグなど要求されるフォーマットに応じて設計されたモデルを提供しています。また、検証・チェックを装置内に統合した「Oculus」テクノロジーを搭載したロボットもご用意しています。',
    siglas: '調剤ロボット',
    robottitle: 'ロボットの製品ラインナップを探る',
    robotdescription:
      'PDAの準備における自動化を最適化したい場合、当社のロボットは理想的なパートナーです。当社のPDA用ロボットは高度に自動化されたものから、より少ない自動化が必要なものまで幅広く取り揃えており、お客様の特定のニーズに合わせることができます。当社の先進技術と提供する汎用FSPホッパーにより、較正への依存を大幅に減らすことができます。さらに、当社のロボットには組み込みのOCULUSシステムが搭載されており、各製品の詳細な写真証拠やすべての投与量の徹底的な分析を提供します。',
    catalog: 'カタログをダウンロード',
    diff: '当社のPDA用ロボットの主な利点',
    diff1: '汎用FSP',
    diff1a:
      '汎用FSPを使用すると、複雑な操作や較正なしに必要な医薬品を正確なタイミングで使用できます。当社の革新的な技術は、形状やサイズに関係なく、使用したい医薬品の種類を保証します。お客様は製造時の自由度が向上します。',
    diff2: '高い回転数',
    diff2a:
      '最も使用される分子の供給を確保するため、精密な較正を行います。当社の専門家は、この選択を検証するために徹底的な研究を行い、正確な較正と効率的な自動化を保証します。このアプローチにより、最適な自動化が実現します。',
    diff3: '全体のトレーサビリティ',
    diff3a:
      '当社のロボットを使用すると、医薬品の管理がこれまで以上に簡単になります。Sevemコードをスキャンして、製造された各投与量のロットと賞味期限を登録するだけで済みます。この迅速かつ効率的なプロセスにより、医薬品の詳細な追跡が可能になり、正確かつ安全な管理が保証されます。',
    robotnews: 'OCULUSシステム',
    robotnews2:
      'Robotikでは、視覚技術を用いて製造されたすべての製品をチェックおよび分析するOCULUSシステムを開発しました。OCULUSを使用すれば、製造時に手動での確認にかかる時間を省略できます。また、Robotikのロボットで製造されたすべての製品の写真証拠を常に残します。要するに、OCULUSは完全な安心感を提供します。',
    discover: '各PDAロボットの特徴を発見する',
    discover2: '選択する',
    tablerobots: 'あらゆるニーズに適した理想のPDAロボット',
    footer:
      '市場で最高の単回使用PDAソリューション。私たちは、管理プロセスに人工知能を実装するのに精通しています。',
    corfirmform: 'フォームを受け取りました！',
    corfirmform2:
      '私たちのチームが提供された情報を確認し、近々お客様に連絡いたします。ご関心をお寄せいただきありがとうございます！',
    tel: '電話',
  },
  myprofile: {
    edit: '編集',
    user: 'ユーザー',
    name: '氏名',
    mail: 'メールアドレス',
    number: '電話番号',
    country: '国',
    state: '州',
    city: '市',
    address: '住所',
    zip: '郵便番号',
    company: '会社',
    role: '役割',
    save: '変更を保存',
    delete: 'ユーザーの削除',
    verify: 'メール確認済み',
    verifyinfo: 'このオプションを無効にすると、ユーザーに自動的に確認メールが送信されます。',
    banned: '無効',
    apply: 'アカウントを無効にする',
    new: '新しいユーザーを作成',
    permit: '*.jpeg、*.jpg、*.png、*.gifが許可されています',
    max: '最大サイズ',
    management: '管理',
    search: '検索...',
    list: 'ユーザーリスト',
    changecenter: 'センターの変更',
    resetpass: 'パスワードをリセット',
    confirmdelete: '選択したユーザーを削除しますか？',
    dense: '密度',
    confirmreset: 'メールが正常に送信されました',
    quick: '権限の迅速な編集',
  },
  pages: {
    pages: 'ページ',
    about: '私たちについて',
    contact: 'IDOOSEに連絡する',
    inicio: 'ホーム',
    info: '情報',
    community: 'コミュニティ',
    post: '投稿',
    robot: '塗布ロボット',
  },
  about: {
    who: '私たちは誰ですか？',
    about: '私たちについて',
    contact: 'IDOOSEに連絡する',
    inicio: 'ホーム',
    info: '情報',
    community: 'コミュニティ',
    post: '投稿',
    whotitle: '私たちは',
    are: '誰ですか？',
    mision: '私たちの使命は、',
    mision2: '精密な単回投薬を通じて患者の健康と幸福を向上させることです。',
    mision3: '個別に安全で効果的な管理を確保します。',
    whatis: 'IDOOSEとは？',
    text: '私たちは薬の管理プログラム以上のものです。私たちは人々の健康にポジティブな変化をもたらすことを目指しています。最も先進的でアクセス可能な管理プログラムを提供することで、薬の管理を単純化するだけでなく、コミュニティのつながりと総合的な幸福を促進します。私たちのAIにより、市場で最も安全で信頼性の高いサービスを提供しています。IDOOSEでは、より健康的でシンプルで安全な人生への道を提供しています。',
  },
  contact: {
    where: 'どこに',
    find: 'いますか？',
    contact: 'お気軽にお問い合わせください。喜んで対応いたします。',
    name: '名前',
    email: 'メール',
    subjet: '件名',
    message: 'メッセージをこちらに入力してください。',
    submit: '送信',
  },
  rulesimport: {
    list: 'インポートパラメータ',
    form: '販売レポート',
    step1: 'ファイルの選択',
    step2: '競合の確認',
    step3: 'ファイルのアップロード',
    importcorrect: '正常',
    sugest: '推奨事項',
    new: '新規',
    noconflicts: 'ファイルが正常に検証され、競合は検出されませんでした。',
    sureimport: 'データベースが更新されました。インポートを完了するには、終了を押してください。',
    importcomplete: 'インポートが正常に完了しました。',
  },
  calendar: {
    title: 'カレンダー',
    event: '新しいイベント',
    month: '月',
    week: '週',
    day: '日',
    agenda: '予定',
    today: '今日',
    new: 'イベントを追加',
    new2: 'タイトル',
    description: '説明',
    allday: '終日',
    start: '開始日',
    end: '終了日',
  },
  tablerobots: {
    tolvas: 'ホッパー数',
    univers: '汎用FSP',
    width: '幅（cm）',
    height: '高さ（cm）',
    depth: '奥行き（cm）',
  },
  yup: {
    yup_min: '短すぎます!',
    yup_max: '長すぎます!',
    yup_required: 'フィールドは必須です!',
    yup_email: '電子メールは有効な電子メール アドレスである必要があります!',
    yup_phone: '電話番号には数字のみを含めてください!',
  },
  cookie_consent: {
    acept: '受け入れる',
    reject: '衰退',
    message:
      '当社のウェブサイトはトラフィックを分析し、サービスを向上させるために Cookie を使用します。',
  },
  form_geometric: {
    1: '長形',
    2: 'アンプル',
    3: 'リング',
    4: 'アーチ',
    5: '弾丸',
    6: '双凸',
    7: 'カプセル',
    8: 'カートリッジ',
    9: '円筒形',
    10: '凹型',
    11: '四角形',
    12: '楕円形',
    13: '球形',
    14: 'ガス',
    15: '吸入器',
    16: '涙形',
    17: '液体',
    18: 'アップル形',
    19: '紡錘形',
    20: '八角形',
    21: '楕円',
    22: '粉末',
    23: '長方形',
    24: '丸型',
    25: '菱形',
    26: '封筒',
    27: '三角形',
    28: 'バイアル',
  },
  form_size: {
    0: '未定義',
    1: '2 mm (マイクロ)',
    2: '4 mm (小)',
    3: '8 mm (中)',
    4: '12 mm (大)',
    5: '18.0 mm (非常に大きい)',
    6: '23.3 mm (特大)',
  },
  form_desc: {
    form_geometric: '幾何学的形状',
    form_size: 'サイズ',
    form_slot: 'スロット',
  },
  termsconditions: {
    title: 'IDOOSEの利用規約',
    subtitle: 'IDOOSEへようこそ',
    parraf1:
      'IDOOSEをお選びいただきありがとうございます。ソフトウェアを使用する前に、この利用規約を注意深くお読みください。IDOOSEを使用することで、これらの利用規約に同意したことになります。これらの条件に同意できない場合は、当ソフトウェアを使用しないでください。',
    subtitle2: '1. 契約の範囲と受諾',
    subtitle3: '1.1. 契約に含まれるサービス',
    parraf2:
      'この契約は、idoose.comおよびIDOOSE ROBOTIK SLが提供する他のソフトウェア、ウェブサイト、サービス（総称して「サービス」）に適用されます。',
    subtitle4: '1.2. この契約の受諾',
    parraf3:
      'IDOOSEのサービスを使用することにより、修正なしでこれらの利用規約に同意したことになります。同意できない場合は、サービスを使用しないでください。',
    subtitle5: '1.3. 契約の変更',
    parraf4:
      'IDOOSE ROBOTIK SLは、これらの利用規約をいつでも変更する権利を有します。変更があった場合、電子メールまたは当社のウェブサイトを通じて通知します。変更後もサービスを継続して使用することにより、変更を受け入れたものとみなされます。',
    subtitle6: '2. サービスの使用と制限',
    subtitle7: '2.1. ソフトウェアの使用',
    parraf5:
      'IDOOSEはライセンスされており、販売されていません。ソフトウェアの技術的な制限を遵守し、以下の行為を行わないこと： •ソフトウェアの技術的な制限を回避する •ソフトウェアを逆コンパイル、逆アセンブル、リバースエンジニアリングする（法律で許可されている場合を除く） •この契約で指定された以上のコピーを作成する •他の人がコピーできるようにソフトウェアを公開する •ソフトウェアを貸与、賃貸、貸し出す •ソフトウェアまたはこの契約を第三者に譲渡する。',
    subtitle8: '2.2. ユーザーの義務',
    parraf6:
      'IDOOSEのサービスを責任を持って使用し、IDOOSE ROBOTIK SLまたは他のユーザーに損害を与える可能性のある行為を行わないことを約束します。これには以下が含まれますが、これに限定されません： •違法または未承認の活動にサービスを使用しない •サービスの運用を妨害したり、未承認の方法でサービスにアクセスしない •他のユーザーの個人情報を同意なしに収集しない •ウイルスやその他の有害なソフトウェアをアップロードまたは配布しない。',
    subtitle9: '3. IDOOSEアカウント',
    subtitle10: '3.1. アカウントの作成',
    parraf7:
      '特定のサービスにアクセスするには、IDOOSEアカウントが必要です。アカウント情報とパスワードの機密性を維持する責任はあなたにあります。',
    subtitle11: '3.2. アカウントの使用',
    parraf8:
      'アカウントの下で発生するすべての活動について、あなたが責任を負います。アカウントの不正使用やセキュリティ違反が発生した場合は、直ちにIDOOSE ROBOTIK SLに通知してください。',
    subtitle12: '4. コンテンツ',
    subtitle13: '4.1. コンテンツの所有権',
    parraf9:
      'サービスを通じてアップロードまたは送信するコンテンツは、引き続きあなたの所有物です。IDOOSE ROBOTIK SLは、あなたのコンテンツの所有権を主張しません。',
    subtitle14: '4.2. コンテンツの責任',
    parraf10:
      'サービスを通じてアップロード、保存、送信するコンテンツについて、あなたが唯一責任を負います。あなたは、コンテンツをアップロードするために必要なすべての権利を有しており、コンテンツが第三者の権利を侵害していないことを表明し、保証します。',
    subtitle15: '4.3. コンテンツの確認と削除',
    parraf11:
      'IDOOSE ROBOTIK SLは、サーバーにアップロードされたコンテンツを確認し、これらの利用規約または適用法に違反するコンテンツを独自の裁量で削除する権利を有します。',
    subtitle16: '5. サービスのキャンセル',
    subtitle17: '5.1. ユーザーによるキャンセル',
    parraf12:
      'アカウント管理ページを通じて、いつでもIDOOSEアカウントをキャンセルできます。キャンセルは、請求期間の終了時に有効になります。',
    subtitle18: '5.2. IDOOSE ROBOTIK SLによるキャンセル',
    parraf13:
      'IDOOSE ROBOTIK SLは、理由の有無にかかわらず、通知なしに、いつでもアカウントやサービスへのアクセスをキャンセルする権利を有します。',
    subtitle19: '6. 適用法',
    parraf14:
      'この契約は、法原則の選択に影響を与えることなく、スペインの法律に準拠します。この契約に関連して生じるいかなる紛争も、スペインの裁判所の専属管轄に服するものとします。',
    subtitle20: '7. 連絡先情報',
    parraf15: 'この契約についての質問は、info@idoose.comまでお問い合わせください。',
  },
  privacy: {
    title: 'プライバシーポリシー',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'IDOOSE ROBOTIK SL（住所：Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564、CIF B56740137）は、ユーザーのプライバシーとセキュリティの保護に努めています。このプライバシーポリシーは、ソフトウェアの使用中に収集、使用、保護する情報について説明します。',
    subtitle2: '1. 収集する情報',
    parraf2:
      '以下の種類の情報を収集することがあります： 個人識別情報：名前、住所、電話番号、電子メールアドレス、および自発的に提供するその他の情報。 ソフトウェアの使用情報：ソフトウェアとのやり取りに関するデータ、使用する機能、使用頻度など。 技術情報：デバイスに関する技術データ（IPアドレス、ブラウザの種類、オペレーティングシステム、言語設定など）。',
    subtitle3: '2. 情報の使用',
    parraf3:
      '収集した情報は以下の目的で使用します： ソフトウェアの提供、運用、維持。 ソフトウェアの改善、カスタマイズ、拡張。 ソフトウェアの使用方法の理解と分析。 新機能、製品、サービスの開発。 顧客サービスの提供、アップデートの提供、マーケティングおよびプロモーション目的でのコミュニケーション。 トランザクションの処理およびアカウント管理。 定期的な電子メールの送信。',
    subtitle4: '3. 情報の保護',
    parraf4:
      '個人情報の入力、送信、アクセス時に、さまざまなセキュリティ対策を実施して、個人情報のセキュリティを保護します。ただし、インターネット上での送信方法や電子的な保存方法が100％安全であるとは限りません。',
    subtitle5: '4. 情報の共有',
    parraf5:
      '個人識別情報を第三者に販売、交換、移転することはありません。ただし、信頼できる第三者がソフトウェアの運営、ビジネスの遂行、サービスの提供を支援する場合、この情報の機密性を保持することに同意する限り、これを含みません。また、法律を遵守するため、サイトポリシーを施行するため、または権利、財産、安全を保護するために情報を開示することがあります。',
    subtitle6: '5. ユーザーの権利',
    parraf6:
      '個人データのアクセス、修正、キャンセル、処理に対する異議を申し立てる権利があります。これらの権利を行使するには、info@idoose.comまで電子メールを送信するか、以下の住所に手紙を送ってください：Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564。',
    subtitle7: '6. プライバシーポリシーの変更',
    parraf7:
      'このプライバシーポリシーをいつでも更新する権利を有します。新しいプライバシーポリシーをこのページに掲載することで、変更を通知します。変更を知るために、このプライバシーポリシーを定期的に確認することをお勧めします。',
    subtitle8: '7. 連絡先',
    parraf8:
      'このプライバシーポリシーについて質問がある場合は、以下の連絡先までお問い合わせください：IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 電子メール：info@idoose.com',
  },
};

export default jp;
