import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import Permision from 'src/utils/permits';

import { useTranslate } from 'src/locales';
import { DistributorProfile } from 'src/auth/context/jwt/utils';

import SvgColor from 'src/components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  medicine: icon('ic_medicine'),
  patient: icon('ic_patient'),
  production: icon('ic_production'),
  mery: icon('ic_mery'),
  take: icon('ic_take1'),
  center: icon('ic_center'),
  pharmacy: icon('ic_pharmacy'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const DistributorProfile1 = DistributorProfile();
  
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('menu.general'),
        items: [
          {
            title: t('menu.inicio'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('menu.catalog'),
            path: !Permision(1) ? '#disabled' : paths.dashboard.general.medicines,
            icon: ICONS.medicine,
            disabled: !Permision(1),
          },
          {
            title: t('menu.patient'),
            path: !Permision(29) ? '#disabled' : paths.dashboard.general.patients,
            icon: ICONS.user,
            disabled: !Permision(29),
          },
          {
            title: t('menu.posology'),
            path: !Permision(11) ? '#disabled' : paths.dashboard.general.posology,
            icon: ICONS.calendar,
            disabled: !Permision(11),
          },
          {
            title: t('menu.newpro'),
            path: !Permision(13) ? '#disabled' : paths.dashboard.general.production,
            icon: ICONS.production,
            disabled: !Permision(13),
          },
          {
            title: t('menu.import'),
            path: !Permision(18) ? '#disabled' : paths.dashboard.general.import.root,
            icon: ICONS.folder,
            disabled: !Permision(18),
            children: [
              { title: t('menu.upload'), path: paths.dashboard.general.import.root },
              {
                title: t('menu.parametersimport'),
                path: paths.dashboard.general.import.rules,
              },
            ],
          },
          {
            title: t('menu.electronic'),
            path: !Permision(20) ? '#disabled' : paths.dashboard.general.ia.tv,
            icon: ICONS.blog,
            display: DistributorProfile1 === 2,
            disabled: !Permision(20),
          },
          {
            title: t('menu.virtual'), 
            path: !Permision(20) ? '#disabled' : paths.dashboard.general.ia.root,
            icon:  ICONS.label ,
            disabled: !Permision(20),
            display: DistributorProfile1 === 0 ,
            children: [
              {
                title: t('menu.task'),
                path: !Permision(21) ? '#disabled' : paths.dashboard.general.ia.orders,
                disabled: !Permision(21),
              },
              {
                title: t('menu.electronic'),
                path: !Permision(22) ? '#disabled' : paths.dashboard.general.ia.tv,
                disabled: !Permision(22),
              },
              {
                title: t('menu.availableia'),
                path: !Permision(25) ? '#disabled' : paths.dashboard.general.ia.dps,
                disabled: !Permision(25),
              },
              {
                title: t('menu.match'),
                path: !Permision(26) ? '#disabled' : paths.dashboard.general.ia.merge,
                disabled: !Permision(26),
              },
              {
                title: t('menu.sellparameters'),
                path: !Permision(27) ? '#disabled' : paths.dashboard.general.ia.config,
                disabled: !Permision(27),
              },
            ],
          },
          {
            title: t('menu.form0'),
            path: !Permision(39) ? '#disabled' : paths.dashboard.general.report.root,
            icon: ICONS.file,
            disabled: !Permision(39),
            children: [
              {
                title: t('menu.form1'),
                path: !Permision(40) ? '#disabled' : paths.dashboard.general.report.pvirtual,
                disabled: !Permision(40),
              },
              {
                title: t('menu.form2'),
                path: !Permision(39) ? '#disabled' : paths.dashboard.general.report.pmedicines,
                disabled: !Permision(39),
              },
              {
                title: t('menu.form3'),
                path: !Permision(7) ? '#disabled' : paths.dashboard.general.report.traceability,
                disabled: !Permision(7),
              },
            ],
          },
          {
            title: t('menu.take'),
            path: !Permision(30) ? '#disabled' : paths.dashboard.general.ctakes,
            icon: ICONS.take,
            disabled: !Permision(30),
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('menu.management'),
        items: [
          // USER
          {
            title: t('menu.user'),
            path: !Permision(34) ? '#disabled' : paths.dashboard.user.root,
            icon: ICONS.user,
            disabled: !Permision(34),
            children: [
              {
                title: t('menu.profile'),
                path: !Permision(34) ? '#disabled' : paths.dashboard.user.account,
                disabled: !Permision(34),
              },
              {
                title: t('menu.list'),
                path: !Permision(33) ? '#disabled' : paths.dashboard.user.list,
                disabled: !Permision(33),
              },
            ],
          },

          {
            title: t('menu.centersmanage'),
            path: !Permision(35) ? '#disabled' : paths.dashboard.centers,
            icon: ICONS.center,
            disabled: !Permision(35),
          },

          // ORDER
          {
            title: t('menu.myinstallation'),
            path: !Permision(37) ? '#disabled' : paths.dashboard.install,
            icon: ICONS.pharmacy,
            disabled: !Permision(37),
          },

          // CALENDAR
          /* {
            title: t('menu.calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
            info: (
              <Label color="error" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                NEW
              </Label>
            ),
          }, */
        ],
      },
    ],
    [DistributorProfile1, t]
  );
  return data;
}
